import { SignalTypes } from "../types/signalTypes";
import EmptyToShipperIcon from "assets/icons/empty_to_shipper_icon.svg";
import GateInIcon from "assets/icons/gate_in_icon.svg";
import LoadedOnVesselIcon from "assets/icons/loaded_on_vessel_icon.svg";
import DepartedFromOriginIcon from "assets/icons/departed_from_origin_icon.svg";
import TransshipmentPortArrivalIcon from "assets/icons/transshipment_port_arrival_icon.svg";
import TransshipmentPortDepartureIcon from "assets/icons/transshipment_port_departure_icon.svg";
import ArrivedAtDestinationIcon from "assets/icons/arrived_at_destination_icon.svg";
import DischargedFromVesselIcon from "assets/icons/discharged_from_vessel_icon.svg";
import GateOutIcon from "assets/icons/gate_out_icon.svg";
import ContainerReturnedEmptyIcon from "assets/icons/container_returned_empty_icon.svg";

export const signalToIcon = {
  [SignalTypes.EMPTY_TO_SHIPPER]: EmptyToShipperIcon,
  [SignalTypes.GATE_IN]: GateInIcon,
  [SignalTypes.LOADED_ON_VESSEL]: LoadedOnVesselIcon,
  [SignalTypes.DEPARTED_FROM_ORIGIN]: DepartedFromOriginIcon,
  [SignalTypes.TRANSSHIPMENT_PORT_ARRIVAL]: TransshipmentPortArrivalIcon,
  [SignalTypes.TRANSSHIPMENT_PORT_DEPARTURE]: TransshipmentPortDepartureIcon,
  [SignalTypes.ARRIVED_AT_DESTINATION]: ArrivedAtDestinationIcon,
  [SignalTypes.DISCHARGED_FROM_VESSEL]: DischargedFromVesselIcon,
  [SignalTypes.GATE_OUT]: GateOutIcon,
  [SignalTypes.CONTAINER_RETURNED_EMPTY]: ContainerReturnedEmptyIcon,
};
