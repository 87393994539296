"use client";

import React from "react";
import { LDContext, LDProvider, ProviderConfig } from "launchdarkly-react-client-sdk";
import { useQuery } from "@tanstack/react-query";
import { WithAuthInfoProps, withAuthInfo } from "services/auth";
import { getTenantInfo } from "services/api/config";

import config from "config";

const useFFMock = config.envs.USE_FEATURE_FLAG_MOCK !== "false";
const { LAUNCHDARKLY_CLIENT_ID } = config.envs;

type LaunchDarklyProviderProps = {
  children: React.ReactNode;
} & WithAuthInfoProps;

const LaunchDarklyProvider: React.FC<LaunchDarklyProviderProps> = ({
  children,
  isLoggedIn,
  accessToken,
}) => {
  const { data: tenantInfo } = useQuery({
    queryKey: ["tenantInfo", accessToken],
    queryFn: () => getTenantInfo(accessToken!),
    enabled: isLoggedIn && !!accessToken,
  });

  const LDProviderProps: ProviderConfig = {
    clientSideID: LAUNCHDARKLY_CLIENT_ID,
    reactOptions: {
      useCamelCaseFlagKeys: false,
    },
    options: {
      streaming: true,
    },
    timeout: 2,
    deferInitialization: true,
  };

  // if signed in, set tenant context
  if (tenantInfo) {
    const LDContext: LDContext = {
      kind: "tenant",
      key: tenantInfo.id,
      name: tenantInfo.name,
      tenantName: tenantInfo.name,
    };
    LDProviderProps.context = LDContext;
  }

  if (useFFMock) {
    return <>{children}</>;
  } else {
    return <LDProvider {...LDProviderProps}>{children}</LDProvider>;
  }
};

export default withAuthInfo(LaunchDarklyProvider);
