import styled from "@emotion/styled";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const DropZoneContainer = styled.div<{ isDragging: boolean; disabled: boolean }>`
  border: 1px solid ${(props) => (props.isDragging ? "#888" : "#D5D5D5")};
  border-radius: 3px;
  text-align: center;
  font-family: Arial, sans-serif;
  color: #555;
  background-color: ${(props) => (props.isDragging || props.disabled ? "#f9f9f9" : "#fff")};
  transition: all 0.3s ease;
  width: 555px;
  height: 168px;
  padding: 25px;
  display: flex;
  flex-direction: column;
  align-items: center;

  &:hover {
    cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  }
`;

export const Instructions = styled.p`
  margin: 10px 0;
  color: #868686;
  font-size: 14px;
  width: 312px;
  text-align: center;
  line-height: 21px;
`;

export const HiddenInput = styled.input`
  display: none;
`;

export const FilesContainer = styled.div`
  display: flex;
  flex-direction: column;

  p.totalSize {
    margin: 0;
  }
`;

export const FileList = styled.ul`
  padding-left: 20px;
  max-height: 135px;
  overflow-y: auto;
`;

export const FileItem = styled.li`
  div.file {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
  }

  &:hover {
    background-color: #f9f9f9;
  }
`;

export const RemoveButton = styled.button`
  background: none;
  border: none;
  color: red;
  cursor: pointer;
  font-size: 16px;
`;
