import styled from "@emotion/styled";

export const Content = styled.div`
  margin: 40px 40px;
  font-size: 14px;
  text-align: center;
  line-height: 21px;
  width: 80%;
  height: 70%;
  overflow-y: auto;

  div.containers-info {
    padding: 5px 0px;
  }

  div.containers-title {
    ul {
      list-style: none;
      padding: 0;
    }
  }
`;

export const Popup = styled.div`
  box-sizing: border-box;

  position: absolute;
  width: 423px;
  height: 433px;
  left: 103px;
  top: 238px;

  background: #ffffff;
  border: 1px solid #d5d5d5;
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.15);
  border-radius: 25px;
`;

export const CloseButton = styled.div`
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  padding: 8px;
  width: 80%;
  gap: 6px;
  margin: 40px 40px;

  border: 0.874129px solid #e9e9ee;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.1);
  border-radius: 3px;

  &:hover {
    background: #f5f5f5;
  }

  &:active {
    background: #e9e9ee;
  }

  &:focus {
    outline: none;
  }

  &:disabled {
    background: #f5f5f5;
    color: #b9b9b9;
    cursor: not-allowed;
  }
`;
