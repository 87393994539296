const PlusCircleIcon = ({
  fillColor = "#868686",
  size = 29,
}: {
  fillColor?: string;
  size?: number;
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 29 29"
      fill="none"
    >
      <path
        d="M14.5 27.071C7.607 27.071 2 21.464 2 14.571C2 7.678 7.607 2.071 14.5 2.071C21.393 2.071 27 7.678 27 14.571C27 21.464 21.393 27.071 14.5 27.071ZM14.5 4.071C8.71 4.071 4 8.781 4 14.571C4 20.361 8.71 25.071 14.5 25.071C20.29 25.071 25 20.36 25 14.571C25 8.782 20.29 4.071 14.5 4.071Z"
        fill={fillColor}
      />
      <path
        d="M14.5 21.571C14.2348 21.571 13.9804 21.4656 13.7929 21.2781C13.6054 21.0906 13.5 20.8362 13.5 20.571V8.571C13.5 8.30578 13.6054 8.05143 13.7929 7.86389C13.9804 7.67636 14.2348 7.571 14.5 7.571C14.7652 7.571 15.0196 7.67636 15.2071 7.86389C15.3946 8.05143 15.5 8.30578 15.5 8.571V20.571C15.5 20.8362 15.3946 21.0906 15.2071 21.2781C15.0196 21.4656 14.7652 21.571 14.5 21.571Z"
        fill={fillColor}
      />
      <path
        d="M20.5 15.571H8.5C8.23478 15.571 7.98043 15.4656 7.79289 15.2781C7.60536 15.0906 7.5 14.8362 7.5 14.571C7.5 14.3058 7.60536 14.0514 7.79289 13.8639C7.98043 13.6764 8.23478 13.571 8.5 13.571H20.5C20.7652 13.571 21.0196 13.6764 21.2071 13.8639C21.3946 14.0514 21.5 14.3058 21.5 14.571C21.5 14.8362 21.3946 15.0906 21.2071 15.2781C21.0196 15.4656 20.7652 15.571 20.5 15.571Z"
        fill={fillColor}
      />
    </svg>
  );
};

export default PlusCircleIcon;
