import React, { useEffect, useRef, useState, SetStateAction } from "react";
import { useTranslation } from "react-i18next";
import { useManualUploadStore } from "features/ManualUpload/store/manualUploadStore";
import {
  Container,
  DropZoneContainer,
  FileItem,
  FileList,
  FilesContainer,
  HiddenInput,
  Instructions,
  RemoveButton,
} from "./DragAndDropPDF.styled";
import { PlusCircleIcon, CloseIcon } from "components/icons";

const MAX_FILES = 8; // Define maximum allowed files
const MAX_TOTAL_SIZE = 20 * 1024 * 1024; // 20MB in bytes

type DragAndDropPDFProps = {
  pdfFiles: File[];
  setPdfFiles: React.Dispatch<SetStateAction<File[]>>;
  disabled?: boolean;
  onActivate?: (active: "dragAndDrop" | "inputContainer" | null) => void;
  onValidationChange?: (isValid: boolean) => void;
};

const DragAndDropPDF: React.FC<DragAndDropPDFProps> = ({
  pdfFiles,
  setPdfFiles,
  disabled = false,
  onActivate,
  onValidationChange,
}) => {
  const [isDragging, setIsDragging] = useState(false);
  const [totalSize, setTotalSize] = useState(0);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const { openDrawer } = useManualUploadStore();
  const { t } = useTranslation();

  // Reset file input when the drawer is closed
  useEffect(() => {
    if (!openDrawer && fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  }, [openDrawer]);

  // Activate or deactivate drag-and-drop mode
  useEffect(() => {
    if (pdfFiles.length > 0) {
      onActivate?.("dragAndDrop");
      onValidationChange?.(false);
    } else if (pdfFiles.length === 0 && !disabled) {
      onActivate?.(null);
      onValidationChange?.(true);
    }
  }, [pdfFiles, onActivate, disabled, onValidationChange]);

  // Handle file upload via drag-and-drop or file input
  const handleFiles = (files: File[]) => {
    const validFiles = getValidPdfFiles(files);
    const newFilesSize = calculateTotalFileSize(validFiles);

    if (isFileCountExceeded(validFiles)) {
      alert(t("You can upload a maximum of {{max}} PDF files.", { max: MAX_FILES }));
      return;
    }

    if (isTotalSizeExceeded(newFilesSize)) {
      alert(
        t("Total file size cannot exceed {{max}}MB.", {
          max: MAX_TOTAL_SIZE / (1024 * 1024),
        }),
      );
      return;
    }

    if (validFiles.length > 0) {
      addFiles(validFiles, newFilesSize);
    } else {
      alert(t("Please upload only PDF files."));
    }
  };

  // Utility functions
  const getValidPdfFiles = (files: File[]) =>
    files.filter((file) => file.type === "application/pdf");
  const calculateTotalFileSize = (files: File[]) => files.reduce((acc, file) => acc + file.size, 0);
  const isFileCountExceeded = (newFiles: File[]) => pdfFiles.length + newFiles.length > MAX_FILES;
  const isTotalSizeExceeded = (newFilesSize: number) => totalSize + newFilesSize > MAX_TOTAL_SIZE;
  const addFiles = (newFiles: File[], newFilesSize: number) => {
    setPdfFiles((prev) => [...prev, ...newFiles]);
    setTotalSize((prev) => prev + newFilesSize);
  };

  // Remove a file and update total size
  const handleRemoveFile = (index: number) => {
    // Update the state to remove the file at the given index
    setPdfFiles((prevFiles) => {
      const updatedFiles = prevFiles.filter((_, i) => i !== index);

      // Calculate the new total size after removal
      const newTotalSize = updatedFiles.reduce((acc, file) => acc + file.size, 0);
      setTotalSize(newTotalSize);

      return updatedFiles;
    });

    // Reset the file input value to allow re-uploading the same file
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  return (
    <Container>
      <DropZoneContainer
        disabled={disabled}
        isDragging={isDragging}
        onDragOver={(e) => {
          e.preventDefault();
          setIsDragging(true);
        }}
        onDragLeave={() => setIsDragging(false)}
        onDrop={(e) => {
          if (disabled) return;
          e.preventDefault();
          setIsDragging(false);
          handleFiles(Array.from(e.dataTransfer.files));
        }}
        onClick={() => !disabled && fileInputRef.current?.click()}
      >
        <Instructions>
          {t("Search locally or simply drag and drop the BLs to start tracking your containers.")}
        </Instructions>
        <PlusCircleIcon />
        <HiddenInput
          ref={fileInputRef}
          type="file"
          accept="application/pdf"
          multiple
          onChange={(e) => handleFiles(Array.from(e.target.files || []))}
        />
      </DropZoneContainer>
      {pdfFiles.length > 0 && (
        <FilesContainer>
          <h3>
            {t("Documents Uploaded")} ({pdfFiles.length}/{MAX_FILES})
          </h3>
          <FileList>
            {pdfFiles.map((file, index) => (
              <FileItem key={index}>
                <div className="file">
                  {file.name} ({(file.size / 1024 / 1024).toFixed(2)} MB)
                  <RemoveButton onClick={() => handleRemoveFile(index)} disabled={disabled}>
                    <CloseIcon />
                  </RemoveButton>
                </div>
              </FileItem>
            ))}
          </FileList>
          <p className="totalSize">
            {t("Total Size: {{size}}MB", { size: (totalSize / 1024 / 1024).toFixed(2) })} /{" "}
            {MAX_TOTAL_SIZE / (1024 * 1024)}MB
          </p>
        </FilesContainer>
      )}
    </Container>
  );
};

export default DragAndDropPDF;
