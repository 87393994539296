import { useQuery } from "@tanstack/react-query";
import config from "config";
import { useFlags, useLDClientError } from "launchdarkly-react-client-sdk";
import { useEffect, useState } from "react";
import { getLocalFeatureFlags } from "services/api/config";

// Duplicating feature flags from backend. TODO - move to shared location.
export const FeatureFlagNames = {
  TestKey: "test-key",
  EnableCargoUnitBackend: "enable-cargo-unit-backend",
  EnableCargoUnitFrontend: "enable-cargo-unit-frontend",
  DisableTripCreation: "disable-trip-creation",
  EnableTripsFrontend: "enable-trips-frontend",
  EnableETALogsFrontend: "enable-eta-logs-frontend",
  EnableJourneyAnalyticsDashboard: "enable-journey-analytics-dashboard",
  EnableOceanViewPage: "enable-ocean-view-page",
  EnableJourneyManualUpload: "enable-journey-manual-upload",
  EnableSendDailyUploadEmail: "enable-send-daily-upload-email",
  EnableOceanSignalReport: "enable-ocean-signal-report",
  EnableContainerTrackedEmail: "enable-container-tracked-email",
  EnableRailTrackingTab: "enable-rail-tracking-tab",
  DisablePurchaseOrderColumn: "disable-purchase-order-column",
} as const;

export type FlagNameType = (typeof FeatureFlagNames)[keyof typeof FeatureFlagNames];

const useFFMock = config.envs.USE_FEATURE_FLAG_MOCK !== "false";

export const useFeatureFlags = (accessToken: string | null): Record<FlagNameType, boolean> => {
  const ldConnectionError = useLDClientError();
  const [flags, setFlags] = useState({} as Record<FlagNameType, boolean>);

  const shouldGetBackendFlags = useFFMock || !!ldConnectionError;
  const { data: backendFeatureFlagsData } = useQuery({
    queryFn: () => getLocalFeatureFlags(accessToken!),
    queryKey: ["featureFlags", accessToken],
    enabled: !!accessToken && shouldGetBackendFlags,
  });
  const launchDarklyFlags = useFlags<Record<FlagNameType, boolean>>();
  useEffect(() => {
    if (launchDarklyFlags && Object.keys(launchDarklyFlags).length > 0) {
      setFlags(launchDarklyFlags);
    } else if (backendFeatureFlagsData) {
      setFlags(backendFeatureFlagsData);
    }
  }, [launchDarklyFlags, backendFeatureFlagsData]);
  return flags;
};
