import styled from "@emotion/styled";

export const TextArea = styled.textarea`
  width: 555px;
  height: 150px;
  padding: 25px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-family: Arial, sans-serif;
  font-size: 14px;
  color: #555;
  resize: none; /* Disable resizing */
  outline: none;

  &:focus {
    border-color: #888;
    box-shadow: 0 0 5px rgba(136, 136, 136, 0.5);
  }

  &::placeholder {
    color: #ccc;
  }

  &:disabled {
    background: #f9f9f9;
    color: #ccc;
    cursor: not-allowed;
  }
`;

export const ErrorContainerNumber = styled.div`
  color: red;
  font-size: 12px;
`;

export const HelperText = styled.div`
  font-size: 14px;
  text-align: center;
  color: #6c757d;
  line-height: 1.5;
`;
