import apiClient from "./apiClient";
import { CargoUnitDetails } from "types/cargoUnitTypes";
import { CargoUnitSignalReport } from "features/CargoUnitCard/types/signalTypes";

export async function getCargoUnitDetails(
  accessToken: string,
  cargoUnitId: string | null,
): Promise<CargoUnitDetails | null> {
  if (!cargoUnitId) return null;

  const res = await apiClient.get<CargoUnitDetails>(`/journeys/records/${cargoUnitId}/details`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });

  return { ...res.data };
}

interface PostContainerNumbersResponse {
  newContainerNumbers: string[];
  existingContainerNumbers: string[];
}

export async function postContainerNumbers(
  accessToken: string,
  cargoUnitIds: string[],
): Promise<PostContainerNumbersResponse> {
  const res = await apiClient.post<PostContainerNumbersResponse>(
    "/journeys/container-numbers",
    {
      containerNumbers: cargoUnitIds,
    },
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    },
  );

  return { ...res.data };
}

export async function getCargoUnitSignalReport(
  accessToken: string,
  cargoUnitId: string | null,
  // query: {type: string, addOns: string[]},
): Promise<CargoUnitSignalReport | null> {
  if (!cargoUnitId) return null;
  // TODO: add query params
  const res = await apiClient.get<CargoUnitSignalReport>(
    `/journeys/records/${cargoUnitId}/signal-report`,
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      // params: query,
    },
  );

  return { ...res.data };
}
