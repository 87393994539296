import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useRefinementList } from "react-instantsearch";
import {
  ButtonWrapper,
  Dropdown,
  DropdownSection,
  FilterCategoryButton,
} from "./FilterDropdown.styled";
import FilterIcon from "assets/icons/filter.svg";
import ArrowRight from "assets/icons/arrow_right_icon.svg";
import config from "config";
import { FilterAttributes } from "types/filterTypes";
import FilterCategoryDisplaySearch from "./FilterCategoryDisplaySearch";
import FilterCategoryDisplay from "./FilterCategoryDisplay";

export const DISPLAY_LIMIT = 10;
const { USE_BACKEND_RECORDS } = config.envs;

type FilterCategoryProps = {
  attribute: string;
  searchable: boolean;
  display: string;
  filterItemsDisplay?: Record<string, string>;
};

const FilterCategory = ({
  attribute,
  display,
  filterItemsDisplay,
  searchable,
}: FilterCategoryProps) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState<boolean>(false);
  const useRefinementListReturn = useRefinementList({
    attribute,
    limit: DISPLAY_LIMIT,
  });

  return (
    <>
      <FilterCategoryButton
        onClick={() => setOpen(!open)}
        className={"categoryButton " + (open ? "active" : "")}
      >
        {t(display)}
        <img src={ArrowRight} alt={open ? "Close" : "Open"} className={open ? "open" : ""} />
      </FilterCategoryButton>
      {open && (
        <>
          {searchable ? (
            <FilterCategoryDisplaySearch
              attribute={attribute}
              open={open}
              filterItemsDisplay={filterItemsDisplay}
              useRefinementListReturn={useRefinementListReturn}
            />
          ) : (
            <FilterCategoryDisplay
              filterItemsDisplay={filterItemsDisplay}
              useRefinementListReturn={useRefinementListReturn}
            />
          )}
        </>
      )}
    </>
  );
};

type FilterDropdownProps = {
  filterAttributes: FilterAttributes[];
  filterItemsDisplay?: Record<string, string>;
};

const FilterDropdown = ({ filterAttributes, filterItemsDisplay }: FilterDropdownProps) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const tooltipRef = useRef<HTMLDivElement>(null);
  const { t } = useTranslation();

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (tooltipRef.current && !tooltipRef.current.contains(event.target as Node)) {
        setShowDropdown(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <ButtonWrapper ref={tooltipRef} className="wrapper">
      <button
        onClick={() => setShowDropdown(!showDropdown)}
        disabled={USE_BACKEND_RECORDS === "true"}
      >
        <img src={FilterIcon} alt="Filter" />
        <span>{t("Filter")}</span>
      </button>
      <Dropdown showDropdown={showDropdown}>
        {filterAttributes.map(({ title, attributes }) => (
          <DropdownSection key={title}>
            <div className="sectionTitle">{t(`Add Filter To ${title}`)}</div>
            <div className="sectionContent">
              {attributes.map(({ attribute, display, searchable }) => (
                <FilterCategory
                  key={attribute}
                  attribute={attribute}
                  display={display}
                  filterItemsDisplay={filterItemsDisplay}
                  searchable={!!searchable}
                />
              ))}
            </div>
          </DropdownSection>
        ))}
      </Dropdown>
    </ButtonWrapper>
  );
};

export default FilterDropdown;
