import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Index } from "react-instantsearch";
import { useQuery } from "@tanstack/react-query";
import { withAuthInfo, WithLoggedInAuthInfoProps } from "@propelauth/react";
import { useDocumentTitle } from "hooks/useDocumentTitle";
import { ALGOLIA_INDEXES, IndexNameProvider } from "hooks/useIndexName";
import { JOURNEY_VIEWS, JourneyViewProvider } from "hooks/useJourneyView";
import { getTenantInfo } from "services/api/config";
import FilterDropdown from "components/FilterDropdown";
import CurrentFilters from "components/CurrentFilters";
import ActionButtons from "features/Tracking/components/ActionButtons";
import AlgoliaConfigure from "features/Tracking/components/AlgoliaConfigure/AlgoliaConfigure";
import ArchivedButton from "features/Tracking/components/AlgoliaArchivedButton";
import Search from "features/Tracking/components/Search";
import ExcelExportButton from "features/Tracking/components/ExcelExportButton.tsx";
import AlgoliaCargoUnitTable from "features/CargoUnit/components/AlgoliaCargoUnitTable";
import CargoUnitCard from "features/CargoUnitCard";
import UploadDocumentsButton from "features/ManualUpload/components/ManualUploadButton";
import UploadDocumentsDrawer from "features/ManualUpload/components/ManualUploadDrawer";
import { attributesDisplay, filterAttributes } from "features/CargoUnit/constants/filters";
import SaveSearchButton from "components/SaveSearchButton";
import { FeatureFlagNames, useFeatureFlags } from "hooks/useFeatureFlags";

// TODO @piercebb implement BackendCargoUnitTable when USE_BACKEND_RECORDS is true
const CargoUnitView = withAuthInfo(({ accessToken }: WithLoggedInAuthInfoProps) => {
  const { t } = useTranslation();
  const featureFlags = useFeatureFlags(accessToken);

  useDocumentTitle(t("Container"));

  const { data: tenantInfo } = useQuery({
    queryKey: ["tenantInfo", accessToken],
    queryFn: () => getTenantInfo(accessToken),
  });

  // Remove division filter if tenant does not use divisions
  const filteredAttributes = useMemo(() => {
    if (tenantInfo?.usesDivisions) {
      return filterAttributes.map((filterAttribute) => ({
        ...filterAttribute,
        attributes: filterAttribute.attributes.filter(
          (attribute) => attribute.attribute !== "division",
        ),
      }));
    }
    return filterAttributes;
  }, [tenantInfo?.usesDivisions]);

  return (
    <JourneyViewProvider value={JOURNEY_VIEWS.FULL_JOURNEY}>
      <IndexNameProvider value={ALGOLIA_INDEXES.JOURNEY}>
        <Index indexName={ALGOLIA_INDEXES.JOURNEY}>
          <AlgoliaConfigure />
          <ActionButtons title="Container">
            <Search />
            <SaveSearchButton />
            <FilterDropdown filterAttributes={filteredAttributes} />
            <ArchivedButton />
            <ExcelExportButton />
            {featureFlags[FeatureFlagNames.EnableJourneyManualUpload] && <UploadDocumentsButton />}
          </ActionButtons>
          <CurrentFilters attributesDisplay={attributesDisplay} />
          <AlgoliaCargoUnitTable />
          <CargoUnitCard />
          <UploadDocumentsDrawer />
        </Index>
      </IndexNameProvider>
    </JourneyViewProvider>
  );
});

export default CargoUnitView;
