import React, { useRef, useState } from "react";
import { validateContainerNumber } from "utils/containerValidator";
import { TextArea, ErrorContainerNumber, HelperText } from "./ContainerInputList.styled";
import { useTranslation } from "react-i18next";

type Props = {
  value: string;
  disabled?: boolean;
  onChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  onValidationChange: (isValid: boolean) => void; // Callback to inform parent about validation state
};

const ContainerInputList: React.FC<Props> = ({ value, disabled, onChange, onValidationChange }) => {
  const { t } = useTranslation();
  const [invalidContainerNumbers, setInvalidContainerNumbers] = useState<string[]>([]);
  const debounceTimer = useRef<NodeJS.Timeout | null>(null);

  const validateLines = (input: string) => {
    const lines = input
      .split("\n")
      .map((line) => line.trim())
      .filter((line) => line !== ""); // Remove empty lines
    const errors: string[] = [];

    if (lines.length === 0) {
      // If there are no valid lines, mark as invalid
      onValidationChange(false);
      setInvalidContainerNumbers([]);
      return;
    }

    lines.forEach((line, index) => {
      const validationResult = validateContainerNumber(line);
      if (!validationResult.isValid) {
        errors.push(
          t("Line {{line}}: {{error}}", {
            line: index + 1,
            error: validationResult.errorMessages.map((message) => t(message)).join(", "),
          }),
        );
      }
    });

    setInvalidContainerNumbers(errors);
    onValidationChange(errors.length === 0 && lines.length > 0); // Valid if no errors and there are lines
  };

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    onChange(e);

    // Clear existing debounce timer
    if (debounceTimer.current) {
      clearTimeout(debounceTimer.current);
    }

    // Set new debounce timer
    debounceTimer.current = setTimeout(() => {
      validateLines(e.target.value);
    }, 300);
  };

  const handleBlur = (e: React.FocusEvent<HTMLTextAreaElement>) => {
    validateLines(e.target.value);
  };

  return (
    <>
      <TextArea
        placeholder={t("Enter container numbers, one per line.") as string}
        value={value}
        onChange={handleChange}
        onBlur={handleBlur}
        disabled={disabled}
      />
      <HelperText>{t("containerNumberHelperText")}</HelperText>
      {invalidContainerNumbers.length > 0 && (
        <ErrorContainerNumber>
          {invalidContainerNumbers.map((error, index) => (
            <div key={index}>{error}</div>
          ))}
        </ErrorContainerNumber>
      )}
    </>
  );
};

export default ContainerInputList;
