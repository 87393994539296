import styled from "@emotion/styled";

export const DrawerContainer = styled.div<{ width?: string }>`
  width: ${(props) => props.width};
  height: 100%;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 1001;
  transition: width 0.4s ease;
  overflow: hidden;
  border-top-left-radius: 25px;
  display: flex;
  border-radius: 16px 0px 0px 16px;
  background: var(--Primary-Base-White, #fff);
  box-shadow: 0px 1px 12px 0px rgba(0, 0, 0, 0.15);
`;

export const CloseButton = styled.button`
  position: absolute;
  top: 6px;
  left: 0px;
  background: transparent;
  border: none;
  outline: none;
  z-index: 2;

  img {
    cursor: pointer;
  }
`;

export const ContentContainer = styled.div`
  padding: 33px;
  display: flex;
`;
