import { createContext, useContext } from "react";

// needs to be kept in sync with the backend
export const JOURNEY_VIEWS = {
  FULL_JOURNEY: "full",
  OCEAN: "ocean",
} as const;

export type JourneyView = (typeof JOURNEY_VIEWS)[keyof typeof JOURNEY_VIEWS];

// Create a context for the journey view we're looking at
const JourneyViewContext = createContext<JourneyView | undefined>(undefined);

// Custom hook to access the journey view
export const useJourneyView = () => {
  return useContext(JourneyViewContext);
};

export const JourneyViewProvider = JourneyViewContext.Provider;
