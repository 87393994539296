import { useTranslation } from "react-i18next";
import { CloseButton, Content, Popup } from "./ManualUploadPopup.styled";
import UploadingIcon from "assets/icons/uploading_icon.svg";
import CloseIcon from "assets/icons/close_icon.svg";

interface ManualUploadPopupProps {
  isLoading: boolean;
  isError: boolean;
  uploadErrorMessage: string;
  isSuccess: boolean;
  onClose: () => void;
  newContainers: string[];
  existingContainers: string[];
}

const ManualUploadPopup = ({
  isLoading,
  isError,
  uploadErrorMessage,
  isSuccess,
  onClose,
  newContainers,
  existingContainers,
}: ManualUploadPopupProps) => {
  const { t } = useTranslation();
  return (
    <Popup>
      <Content>
        <div>
          <img src={UploadingIcon} alt="uploading icon" />
        </div>
        {isLoading && <div className="containers-info">{`${t("Processing")}...`}</div>}
        {isError && (
          <>
            <div className="containers-info"> {t(uploadErrorMessage)}</div>
            <div className="containers-info">
              {" "}
              {t(
                "Encountered an error submitting container information. Please wait a while and try again.",
              )}
            </div>
          </>
        )}
        {isSuccess && (
          <>
            <div className="containers-info">
              {t("We are currently processing your data to begin tracking your containers.")}
            </div>
            {newContainers.length > 0 && (
              <div className="containers-title">
                {t("Containers Scheduled for Tracking:")}
                <ul>
                  {newContainers.map((container) => (
                    <li key={container}>{container}</li>
                  ))}
                </ul>
              </div>
            )}

            {existingContainers.length > 0 && (
              <div className="containers-title">
                {t("Containers Already Being Tracked:")}
                <ul>
                  {existingContainers.map((container) => (
                    <li key={container}>{container}</li>
                  ))}
                </ul>
              </div>
            )}
            <div className="containers-info">
              {t(
                "You will receive an email notification once processing is complete and tracking commences.",
              )}
            </div>
          </>
        )}
      </Content>
      <CloseButton onClick={onClose}>
        <img src={CloseIcon} alt="close" />
        <span>{t("Close")}</span>
      </CloseButton>
    </Popup>
  );
};

export default ManualUploadPopup;
