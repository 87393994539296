export const SignalTypes = {
  EMPTY_TO_SHIPPER: "EMPTY_TO_SHIPPER",
  GATE_IN: "GATE_IN",
  LOADED_ON_VESSEL: "LOADED_ON_VESSEL",
  DEPARTED_FROM_ORIGIN: "DEPARTED_FROM_ORIGIN",
  TRANSSHIPMENT_PORT_ARRIVAL: "TRANSSHIPMENT_PORT_ARRIVAL",
  TRANSSHIPMENT_PORT_DEPARTURE: "TRANSSHIPMENT_PORT_DEPARTURE",
  ARRIVED_AT_DESTINATION: "ARRIVED_AT_DESTINATION",
  DISCHARGED_FROM_VESSEL: "DISCHARGED_FROM_VESSEL",
  GATE_OUT: "GATE_OUT",
  CONTAINER_RETURNED_EMPTY: "CONTAINER_RETURNED_EMPTY",
};

export type SignalType = keyof typeof SignalTypes;

export type ReportSignal = {
  signal: SignalType;
  conveyorName: string;
  locationName: string;
  occurredAt: number; // DateTime
};

export type CargoUnitSignalReport = {
  reportType: string;
  addOns?: string[];
  signals: ReportSignal[];
};
