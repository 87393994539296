import { useMutation, useQuery } from "@tanstack/react-query";
import { getSavedFilter, upsertFilter } from "services/api/filters";

export const useFetchSavedUiState = (accessToken: string) =>
  useQuery({
    queryFn: () => getSavedFilter(accessToken),
    queryKey: ["getSavedUiState", accessToken],
  });

export const useSetSavedUiState = (accessToken: string) =>
  useMutation({
    mutationFn: ({ uiState }: { uiState: string }) => upsertFilter(accessToken, uiState),
  });
