import { useToggleRefinement } from "react-instantsearch";
import { Configure } from "react-instantsearch";

/* 
  This is set high to show all options.
  To show fewer values at once and search options, see FilterDropdown README
*/
const MAX_VALUES = 300;

const AlgoliaConfigure = ({ filters }: { filters?: string }) => {
  // This will toggle the archived filter on and off.
  const { value: ArchivedValue } = useToggleRefinement({ attribute: "archived" });
  const archivedFilter = `archived:${ArchivedValue.isRefined}`;
  const appliedFilters = filters ? `${archivedFilter} ${filters}` : archivedFilter;

  return <Configure filters={appliedFilters} maxValuesPerFacet={MAX_VALUES} />;
};

export default AlgoliaConfigure;
