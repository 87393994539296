import { ALGOLIA_INDEXES, IndexName } from "hooks/useIndexName";
import apiClient from "./apiClient";
import { JourneyView } from "hooks/useJourneyView";

// Define the endpoints to export records depending on the Algolia index
export const indexEndpoints = {
  [ALGOLIA_INDEXES.TRIP]: "tracking",
  [ALGOLIA_INDEXES.JOURNEY]: "journeys",
};

export async function exportRecords(
  accessToken: string,
  index: IndexName,
  archived: boolean = false,
  searchValue: string = "",
  refinementList:
    | {
        [attribute: string]: string[];
      }
    | undefined = {},
  filters: string | undefined = "",
  journeyView?: JourneyView,
) {
  const params: ExportRecordsParams = {
    archived,
    searchValue,
    refinementList,
    filters,
  };

  if (journeyView) {
    params.journeyView = journeyView;
  }

  const res = await apiClient.get<string>(`/${indexEndpoints[index]}/records/export`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    params,
    responseType: "blob",
  });
  return res.data;
}

interface ExportRecordsParams {
  archived: boolean;
  searchValue: string;
  refinementList: {
    [attribute: string]: string[];
  };
  filters: string;
  journeyView?: JourneyView;
}
