import { useTranslation } from "react-i18next";
import UploadIcon from "assets/icons/upload_icon.svg";
import { useManualUploadStore } from "features/ManualUpload/store/manualUploadStore";

const ManualUploadButton = () => {
  const { t } = useTranslation();
  const { setOpenDrawer } = useManualUploadStore();

  return (
    <button type="button" onClick={() => setOpenDrawer(true)}>
      <img src={UploadIcon} alt="Save Icon" width={16} />
      {t("Manual Upload")}
    </button>
  );
};

export default ManualUploadButton;
