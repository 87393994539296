import { create } from "zustand";

type ManualUploadStore = {
  openDrawer: boolean;
  setOpenDrawer: (openDrawer: boolean) => void;
};

export const useManualUploadStore = create<ManualUploadStore>((set) => ({
  openDrawer: false,
  setOpenDrawer: (openDrawer) => set({ openDrawer }),
}));
