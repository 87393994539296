import algoliasearch from "algoliasearch/lite";
import { useQuery } from "@tanstack/react-query";
import { useFetchSavedUiState } from "hooks/useSaveUiState";
import { InstantSearch } from "react-instantsearch";
import { getAlgoliaSearchAPIKey } from "services/api/search";
import { withAuthInfo, WithLoggedInAuthInfoProps } from "@propelauth/react";
import config from "config";

const { ALGOLIA_APP_ID } = config.envs;
const SEARCH_DELAY_MS = 500;

type AlgoliaSearchWrapperProps = {
  children: React.ReactNode;
} & WithLoggedInAuthInfoProps;

const AlgoliaSearchWrapper = withAuthInfo(
  ({ accessToken, children }: AlgoliaSearchWrapperProps) => {
    const {
      data: savedUiState,
      isLoading: savedUiStateIsLoading,
      isError: savedUiStateIsError,
    } = useFetchSavedUiState(accessToken);

    const {
      data: algoliasSearchAPIKey,
      isLoading: algoliasSearchAPIKeyIsLoading,
      isError: algoliasSearchAPIKeyIsError,
    } = useQuery({
      queryFn: () => getAlgoliaSearchAPIKey(accessToken),
      queryKey: ["algoliasSearchAPIKey", accessToken],
    });

    if (
      algoliasSearchAPIKeyIsLoading ||
      algoliasSearchAPIKeyIsError ||
      savedUiStateIsLoading ||
      savedUiStateIsError
    ) {
      return null;
    }

    const searchClient = algoliasearch(ALGOLIA_APP_ID, algoliasSearchAPIKey);

    return (
      <InstantSearch
        searchClient={searchClient}
        routing={true}
        initialUiState={JSON.parse((savedUiState?.data as string) || "{}")}
        stalledSearchDelay={SEARCH_DELAY_MS} // allow a beat for searchValue states to apply before refreshing
      >
        {children}
      </InstantSearch>
    );
  },
);

export default AlgoliaSearchWrapper;
