import { useCallback, useEffect, useRef, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { useCargoUnitStore } from "store/cargoUnitStore";
import { useFileURL } from "hooks/useFileURL";
import { useTranslation } from "react-i18next";
import { withAuthInfo, WithLoggedInAuthInfoProps } from "services/auth";
import { getCargoUnitDetails } from "services/api/cargoUnit";
import DocumentsPanel from "./components/DocumentsPanel";
import MoreDetails from "components/MoreDetailsDrawer";
import FilePreview from "features/TripCardModal/components/FilePreview";
import GeneralPanel from "./components/GeneralPanel";
import { Drawer, CloseButton, DrawerContent, TabList, Tab, TabPanel } from "./CargoUnitCard.styled";
import CloseCircleIcon from "assets/icons/close_circle_icon.svg";
import SignalReportPanel from "./components/SignalReportPanel";
import { FeatureFlagNames, useFeatureFlags } from "hooks/useFeatureFlags";
import { JOURNEY_VIEWS, useJourneyView } from "hooks/useJourneyView";

const DEFAULT_WIDTH = "774px";
const HIDDEN_WIDTH = "0px";

const CargoUnitCard = withAuthInfo(({ accessToken }: WithLoggedInAuthInfoProps) => {
  const [drawerWidth, setDrawerWidth] = useState<string>("0px");
  const drawerRef = useRef<HTMLDivElement>(null);
  const { cargoUnitId, setCargoUnitId } = useCargoUnitStore();
  const [activeTab, setActiveTab] = useState<string>("General");
  const { t } = useTranslation();
  const [file, setFile] = useState<{ fileId: string; fileName: string }>({
    fileId: "",
    fileName: "",
  });
  const { fileURL, setFileURL, isFileUrlLoading, isFileUrlError } = useFileURL(
    file.fileId,
    accessToken,
  );
  const featureFlags = useFeatureFlags(accessToken);
  const view = useJourneyView();
  const isOceanView = view === JOURNEY_VIEWS.OCEAN;
  const isOceanSignalReportEnabled = featureFlags[FeatureFlagNames.EnableOceanSignalReport];
  const showOceanSignalReport = isOceanView && isOceanSignalReportEnabled;

  const closeFilePreview = useCallback(() => {
    setFile({ fileId: "", fileName: "" });
    setFileURL("");
  }, [setFileURL]);

  const closeCard = useCallback(() => {
    setCargoUnitId(null);
    setActiveTab("General");
    closeFilePreview();
  }, [closeFilePreview, setCargoUnitId]);

  const showGeneralPanel = useCallback(() => {
    setActiveTab("General");
    closeFilePreview();
  }, [closeFilePreview]);

  const showDocumentsPanel = () => {
    setActiveTab("Documents");
  };

  const showSignalReportPanel = () => {
    setActiveTab("History");
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (drawerRef.current && !drawerRef.current.contains(event.target as Node)) {
        closeCard();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [closeCard]);

  useEffect(() => {
    if (cargoUnitId && file.fileId) {
      setDrawerWidth("80%");
    } else if (cargoUnitId && !file.fileId) {
      setDrawerWidth(DEFAULT_WIDTH);
    } else {
      setDrawerWidth(HIDDEN_WIDTH);
    }
  }, [cargoUnitId, file.fileId]);

  const {
    data: cargoUnitDetails,
    isLoading: isCargoUnitDetailsLoading,
    isError: isCargoUnitDetailsError,
  } = useQuery({
    queryFn: () => getCargoUnitDetails(accessToken, cargoUnitId),
    queryKey: ["getCargoUnitDetails", accessToken, cargoUnitId],
    enabled: cargoUnitId !== null,
  });

  if (isCargoUnitDetailsLoading && isCargoUnitDetailsError) return null;

  const details = cargoUnitDetails?.data;
  const {
    vendor,
    containerNumber,
    lineItems,
    billOfLadingNumbers,
    purchaseOrderNumbers,
    milestones,
  } = details ?? {};

  return (
    <Drawer ref={drawerRef} width={drawerWidth}>
      <CloseButton onClick={closeCard} aria-label="Close">
        <img src={CloseCircleIcon} alt="Close Icon" />
      </CloseButton>
      <DrawerContent>
        <TabList>
          <Tab active={activeTab === "General"} onClick={showGeneralPanel}>
            {t("General")}
          </Tab>
          <Tab active={activeTab === "Documents"} onClick={showDocumentsPanel}>
            {t("Documents")}
          </Tab>
          {showOceanSignalReport && (
            <Tab active={activeTab === "History"} onClick={showSignalReportPanel}>
              {t("Event History")}
            </Tab>
          )}
        </TabList>
        {activeTab === "Documents" && (
          <TabPanel>
            <DocumentsPanel
              documents={details?.documents ? details.documents : []}
              setFile={setFile}
            />
          </TabPanel>
        )}
        {activeTab === "General" && (
          <TabPanel>
            <GeneralPanel
              vendor={vendor}
              container={containerNumber}
              lineItems={lineItems}
              billOfLadingNumbers={billOfLadingNumbers}
              purchaseOrderNumbers={purchaseOrderNumbers}
              milestones={milestones}
            />
          </TabPanel>
        )}
        {activeTab === "History" && (
          <TabPanel>
            <SignalReportPanel />
          </TabPanel>
        )}
      </DrawerContent>
      <MoreDetails show={!!file.fileId} onClose={closeFilePreview}>
        {isFileUrlLoading && <p>{t("Loading file...")}</p>}
        {isFileUrlError && <p>{isFileUrlError}</p>}
        {!isFileUrlLoading && !isFileUrlError && fileURL && (
          <FilePreview fileId={file.fileId} fileName={file.fileName} fileURL={fileURL} />
        )}
      </MoreDetails>
    </Drawer>
  );
});

export default CargoUnitCard;
