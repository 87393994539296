import { useCallback, useState } from "react";
import {
  ColumnSizingState,
  flexRender,
  getCoreRowModel,
  OnChangeFn,
  useReactTable,
  ColumnDef,
  VisibilityState,
} from "@tanstack/react-table";
import { useCargoUnitStore } from "store/cargoUnitStore";
import { CargoUnit } from "types/cargoUnitTypes";
import { Container, Table, TableCell, TableRow } from "./JourneysTable.styled";
import TableHeaders from "components/TableHeader";

type JourneysTableParams = {
  journeys: CargoUnit[];
  columns: ColumnDef<CargoUnit>[];
  columnVisibilityOverrides?: VisibilityState;
};

const JourneysTable = ({ journeys, columns, columnVisibilityOverrides }: JourneysTableParams) => {
  const { cargoUnitId: activeCargoUnitId, setCargoUnitId: setActiveCargoUnitId } =
    useCargoUnitStore();

  const [columnSizing, setColumnSizing] = useState<ColumnSizingState>(() => {
    const resultingState: ColumnSizingState = {};
    for (const { id, size } of columns) {
      if (id && size) {
        resultingState[`${id}`] = size;
      }
    }
    return resultingState;
  });

  const handleOnColumnSizingChange: OnChangeFn<ColumnSizingState> = useCallback((value) => {
    setColumnSizing((state) => ({
      ...state,
      ...(typeof value === "function" ? value?.(state) : value),
    }));
  }, []);

  const table = useReactTable({
    data: journeys,
    columns,
    enableColumnResizing: true,
    getCoreRowModel: getCoreRowModel(),
    columnResizeMode: "onChange",
    defaultColumn: {
      size: 150,
      minSize: 80,
    },
    // TODO: Add dynamic pinning
    initialState: {
      columnPinning: {
        left: ["cargoId", "oceanCarrier"],
        right: [],
      },
    },
    onColumnSizingChange: handleOnColumnSizingChange,
    state: {
      columnSizing,
      columnVisibility: {
        id: false,
        ...columnVisibilityOverrides,
      },
    },
  });

  function openCargoUnitCard(cargoUnitId: string) {
    const selectedText = window.getSelection()?.toString();
    if (selectedText) return;
    setActiveCargoUnitId(cargoUnitId);
  }

  return (
    <Container data-testid="cargo-unit-table">
      <Table tableWidth={table.getTotalSize()}>
        <TableHeaders table={table} />
        <tbody>
          {table.getRowModel().rows.map((row) => (
            <TableRow
              key={row.id}
              onClick={() => openCargoUnitCard(row.getValue("id"))}
              className={activeCargoUnitId === row.getValue("id") ? "active" : ""}
            >
              {row.getVisibleCells().map((cell) => (
                <TableCell
                  key={cell.id}
                  columnWidth={cell.column.getSize()}
                  columnStart={cell.column.getStart("left")}
                >
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </tbody>
      </Table>
    </Container>
  );
};

export default JourneysTable;
