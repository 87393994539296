import { FilterAttributes } from "types/filterTypes";

export const attributesDisplay: Record<string, string> = {
  oceanCarrier: "Carrier",
  vendor: "Vendor",
  departureCountry: "Departure Country",
  departureCity: "Port of Departure",
  endpointCountry: "Destination Country",
  endpointCity: "Port of Destination",
  oceanStatus: "Status",
};

/*
  For process to make a filter attribute searchable, see FilterDropdown README
*/
export const filterAttributes: FilterAttributes[] = [
  {
    title: "Ocean Records",
    attributes: [
      { attribute: "oceanCarrier", display: attributesDisplay.oceanCarrier },
      { attribute: "vendor", display: attributesDisplay.vendor, searchable: true },
      { attribute: "departureCountry", display: attributesDisplay.departureCountry },
      { attribute: "departureCity", display: attributesDisplay.departureCity },
      { attribute: "endpointCountry", display: attributesDisplay.endpointCountry },
      { attribute: "endpointCity", display: attributesDisplay.endpointCity },
      { attribute: "oceanStatus", display: attributesDisplay.oceanStatus },
    ],
  },
];
