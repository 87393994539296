import styled from "@emotion/styled";

export const Panel = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;

  .signalReport {
    display: flex;
    flex-direction: column;
    gap: 12px;

    .content {
      display: flex;
      align-items: center;
      gap: 10px;

      .signal-icon {
        display: flex;
        align-self: end;
        height: 35px;
        width: 35px;
      }

      .wrapper {
        display: flex;
        flex-direction: column;
        align-items: start;
        flex-grow: 1;

        .signal {
          border-left: 2px solid #868686;
          padding-left: 10px;
          width: 80%;
          display: flex;
          flex-direction: column;
          align-items: start;

          .details {
            font-size: 11px;
            color: #868686;
            display: flex;

            .spacer {
              margin-right: 5px;
              margin-left: 5px;
            }

            .detail {
              width: auto;
            }
          }
        }
      }

      .circle {
        width: 7px; /* Diameter of the circle */
        height: 7px; /* Diameter of the circle */
        border-radius: 50%;
        background-color: #5e5e5e;
        margin-right: 5px;
      }

      .signal-spacer {
        display: flex;
        justify-content: start;
        align-items: center;
        margin-bottom: 10px;
        margin-left: 5px;
      }
    }
  }
`;
