import { useCallback, useEffect, useRef, useState, ReactNode } from "react";
import CloseCircleIcon from "assets/icons/close_circle_icon.svg";
import { CloseButton, ContentContainer, DrawerContainer } from "./Drawer.styled";

const DEFAULT_OPEN_WIDTH = "774px";
const DEFAULT_CLOSE_WIDTH = "0px";

type DrawerProps = {
  isOpen: boolean;
  onClose: () => void;
  children: ReactNode;
  openWidth?: string;
  closeWidth?: string;
};

const Drawer = ({
  isOpen,
  onClose,
  children,
  openWidth = DEFAULT_OPEN_WIDTH,
  closeWidth = DEFAULT_CLOSE_WIDTH,
}: DrawerProps) => {
  const drawerRef = useRef<HTMLDivElement>(null);
  const [drawerWidth, setDrawerWidth] = useState(closeWidth);

  useEffect(() => {
    if (isOpen) {
      setDrawerWidth(openWidth);
    } else {
      setDrawerWidth(closeWidth);
    }
  }, [isOpen, openWidth, closeWidth]);

  const handleClickOutside = useCallback(
    (event: MouseEvent) => {
      if (drawerRef.current && !drawerRef.current.contains(event.target as Node)) {
        onClose();
      }
    },
    [onClose],
  );

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [handleClickOutside]);

  return (
    <DrawerContainer width={drawerWidth} ref={drawerRef}>
      <CloseButton aria-label="Close">
        <img src={CloseCircleIcon} onClick={onClose} alt="Close Icon" />
      </CloseButton>
      <ContentContainer>{children}</ContentContainer>
    </DrawerContainer>
  );
};

export default Drawer;
