import apiClient from "./apiClient";

export async function downloadFile(accessToken: string, fileId: string) {
  const res = await apiClient.get(`/files/${fileId}/download`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    responseType: "blob",
  });
  return res.data;
}

export async function postFiles(accessToken: string, files: File[]): Promise<void> {
  const formData = new FormData();
  for (let i = 0; i < files.length; i++) {
    formData.append("files", files[i]);
  }
  await apiClient.post("/files/bulk", formData, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": "multipart/form-data",
    },
  });

  return;
}
