import { capitalize } from "@mui/material";
import SearchBar from "components/SearchBar";
import FilterButton from "features/Tracking/components/FilterButton";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import SearchIcon from "assets/icons/search_icon.svg";
import { RefinementListItem, UseRefinementListReturn } from "./types";
import { DISPLAY_LIMIT } from "./FilterDropdown";
import { useCurrentRefinements } from "react-instantsearch";
import { FilterCategorySection, SearchDisplay } from "./FilterDropdown.styled";

const DEBOUNCE_DELAY = 100;

type FilterCategoryDisplaySearchProps = {
  attribute: string;
  filterItemsDisplay?: Record<string, string>;
  open?: boolean;
  useRefinementListReturn: UseRefinementListReturn;
};

const FilterCategoryDisplaySearch = ({
  attribute,
  filterItemsDisplay,
  open,
  useRefinementListReturn,
}: FilterCategoryDisplaySearchProps) => {
  const { t } = useTranslation();
  const [searchValue, setSearchValue] = useState("");
  const [isRefining, setIsRefining] = useState(false);
  const [filteredItems, setFilteredItems] = useState([] as RefinementListItem[]);
  const [refinedCount, setRefinedCount] = useState(0);
  const { items, refine, searchForItems, isFromSearch } = useRefinementListReturn;
  const { items: refinedAttributes } = useCurrentRefinements({
    includedAttributes: [attribute],
  });

  const onItemClick = (item: RefinementListItem) => {
    if (!item.isRefined && refinedCount >= DISPLAY_LIMIT) {
      return;
    }
    if (isFromSearch) {
      setIsRefining(true);
    }
    refine(item.value);
  };

  useEffect(() => {
    if (searchValue.length === 1) return;
    const debounced = setTimeout(() => {
      searchForItems(searchValue);
      // Wait until it has searched for items with our search value to remove flag
      setIsRefining(false);
    }, DEBOUNCE_DELAY);
    return () => clearTimeout(debounced);
  }, [searchValue, isFromSearch, searchForItems]);

  useEffect(() => {
    if (!open) {
      setSearchValue("");
    }
  }, [open]);

  useEffect(() => {
    // While refining, our search filter is removed from items.
    // Do not refresh filteredItems until isRefining flag is off
    if (!isRefining) {
      setFilteredItems(items);
    }
  }, [items, isRefining, isFromSearch]);

  useEffect(() => {
    if (refinedAttributes.length) {
      const countRefined = refinedAttributes[0].refinements?.length || 0;
      if (countRefined != refinedCount) {
        setRefinedCount(countRefined);
      }
    } else if (refinedCount !== 0) {
      setRefinedCount(0);
    }
  }, [refinedAttributes, refinedCount, setRefinedCount]);

  return (
    <FilterCategorySection>
      <SearchDisplay>
        <div>{refinedCount >= DISPLAY_LIMIT && t("Max values selected")}</div>
      </SearchDisplay>
      <SearchBar
        placeholder={`${t("Search")} ${t("vendor")}`}
        searchQuery={searchValue}
        onChange={(e) => setSearchValue(e.target.value)}
        icon={SearchIcon}
        small={true}
      />
      {filteredItems.length ? (
        filteredItems.map((item, i) => (
          <FilterButton
            key={item.label + i}
            text={t(capitalize(filterItemsDisplay?.[item.label] || item.label))}
            active={item.isRefined}
            onClick={() => onItemClick(item)}
          />
        ))
      ) : (
        <div>{t("No options available")}</div>
      )}
    </FilterCategorySection>
  );
};

export default FilterCategoryDisplaySearch;
