const ALPHA_NUMERICAL_MAP: { [key: string]: number } = {
  A: 10,
  B: 12,
  C: 13,
  D: 14,
  E: 15,
  F: 16,
  G: 17,
  H: 18,
  I: 19,
  J: 20,
  K: 21,
  L: 23,
  M: 24,
  N: 25,
  O: 26,
  P: 27,
  Q: 28,
  R: 29,
  S: 30,
  T: 31,
  U: 32,
  V: 34,
  W: 35,
  X: 36,
  Y: 37,
  Z: 38,
};

export type ContainerNumberSegments = {
  ownerCode: string;
  categoryIdentifier: string;
  serialNumber: string;
  checkDigit: string;
};

export type ContainerNumberValidationResult = {
  isValid: boolean;
  errorMessages: string[];
  containerNumber: string;
  containerNumberSegments: ContainerNumberSegments | null;
};

export function validateContainerNumber(containerNumber: string): ContainerNumberValidationResult {
  try {
    const parsedSegments = parseContainerNumber(containerNumber);

    return {
      isValid: true,
      errorMessages: [],
      containerNumber,
      containerNumberSegments: parsedSegments,
    };
  } catch (error: unknown) {
    return {
      isValid: false,
      errorMessages: [(error as Error).message],
      containerNumber,
      containerNumberSegments: null,
    };
  }
}

function parseContainerNumber(containerNumber: string): ContainerNumberSegments {
  const pattern =
    /^(?<ownerCode>[A-Z]{3})(?<categoryIdentifier>U|J|Z)(?<serialNumber>\d{6})(?<checkDigit>\d)$/;

  const regexResult = pattern.exec(containerNumber.toUpperCase());

  if (!regexResult?.groups) {
    throw new Error("Invalid format for container number.");
  }

  const { ownerCode, categoryIdentifier, serialNumber, checkDigit } = regexResult.groups;

  if (checkDigit !== buildCheckDigit(ownerCode, categoryIdentifier, serialNumber)) {
    throw new Error("Invalid format for container number.");
  }

  return { ownerCode, categoryIdentifier, serialNumber, checkDigit };
}

function buildCheckDigit(
  ownerCode: string,
  categoryIdentifier: string,
  serialNumber: string,
): string {
  const numericalOwnerCode = ownerCode.split("").map((char) => ALPHA_NUMERICAL_MAP[char]);
  const numericalCode = [
    ...numericalOwnerCode,
    ALPHA_NUMERICAL_MAP[categoryIdentifier],
    ...serialNumber.split("").map(Number),
  ];

  const sum = numericalCode.reduce((acc, value, index) => acc + value * Math.pow(2, index), 0);
  const remainder = sum % 11;

  return remainder === 10 ? "0" : remainder.toString();
}
