import styled from "@emotion/styled";

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: center;
`;

export const Header = styled.section`
  align-self: baseline;
`;

export const StartTrackingButton = styled.button`
  border-radius: 3px;
  border: 0.874px solid #e9e9ee;
  background: #fff;
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.1);
  padding: 10px 20px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #5e5e5e;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
  gap: 6px;

  img {
    width: 17px;
    height: 17px;
  }

  &:hover {
    background: #f5f5f5;
  }

  &:active {
    background: #e9e9ee;
  }

  &:focus {
    outline: none;
  }

  &:disabled {
    background: #f5f5f5;
    color: #b9b9b9;
    cursor: not-allowed;
  }
`;
