const CloseIcon = ({ fillColor = "#3A3A3A", size = 13 }: { fillColor?: string; size?: number }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 13 14"
      fill="none"
    >
      <path
        d="M7.23078 7.00356L11.9956 2.24577C12.138 2.10333 12.2181 1.91015 12.2181 1.70872C12.2181 1.50729 12.138 1.31411 11.9956 1.17167C11.8532 1.02924 11.66 0.949219 11.4586 0.949219C11.2572 0.949219 11.0641 1.02924 10.9216 1.17167L6.16436 5.93703L1.40708 1.17167C1.26466 1.02924 1.0715 0.949219 0.870086 0.949219C0.668675 0.949219 0.475514 1.02924 0.333095 1.17167C0.190676 1.31411 0.110666 1.50729 0.110666 1.70872C0.110666 1.91015 0.190676 2.10333 0.333095 2.24577L5.09794 7.00356L0.333095 11.7614C0.262206 11.8317 0.205939 11.9153 0.167542 12.0075C0.129144 12.0997 0.109375 12.1986 0.109375 12.2984C0.109375 12.3983 0.129144 12.4971 0.167542 12.5893C0.205939 12.6815 0.262206 12.7651 0.333095 12.8355C0.403405 12.9064 0.487055 12.9626 0.57922 13.001C0.671386 13.0394 0.770242 13.0592 0.870086 13.0592C0.969929 13.0592 1.06879 13.0394 1.16095 13.001C1.25312 12.9626 1.33677 12.9064 1.40708 12.8355L6.16436 8.0701L10.9216 12.8355C10.992 12.9064 11.0756 12.9626 11.1678 13.001C11.2599 13.0394 11.3588 13.0592 11.4586 13.0592C11.5585 13.0592 11.6573 13.0394 11.7495 13.001C11.8417 12.9626 11.9253 12.9064 11.9956 12.8355C12.0665 12.7651 12.1228 12.6815 12.1612 12.5893C12.1996 12.4971 12.2193 12.3983 12.2193 12.2984C12.2193 12.1986 12.1996 12.0997 12.1612 12.0075C12.1228 11.9153 12.0665 11.8317 11.9956 11.7614L7.23078 7.00356Z"
        fill={fillColor}
      />
    </svg>
  );
};

export default CloseIcon;
